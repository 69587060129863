import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axios from 'axios';

import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent);


import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

import Notifications from 'vue-notification'
Vue.use(Notifications)

// api servidor local
//axios.defaults.baseURL='http://localhost:3000/api/'

//axios.defaults.baseURL='http://191.98.228.86:3300/api/'

// api servidor sin https 
axios.defaults.baseURL='http://68.183.143.58/api/'




Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
