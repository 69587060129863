import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta:{
      libre:true
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },
  {
    path: '/centrodistribucion',
    name: 'centrodistribucion',
    component: () => import('../views/CentrosDistribucion.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },
  {
    path: '/sucursales',
    name: 'sucursales',
    component: () => import('../views/Sucursales.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/vehiculos',
    name: 'vehiculos',
    component: () => import('../views/Vehiculos.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/conductores',
    name: 'conductores',
    component: () => import('../views/Conductores.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },
  {
    path: '/charlies',
    name: 'charlies',
    component: () => import('../views/Charlies.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },
  {
    path: '/items',
    name: 'items',
    component: () => import('../views/Items.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/guardas',
    name: 'guardas',
    component: () => import('../views/Guardas.vue'),
    meta:{
      administrador:true,
      usuario_comun:true
    }
  },

  {
    path: '/configuracion',
    name: 'configuracion',
    component: () => import('../views/Configuracion.vue'),
    meta:{
      administrador:true,
      usuario_comun:false
    }
  }
 
]




const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{



  //si alguien recarga la url debemos tomar ese token e ingresarlo al state

const token_storage=localStorage.getItem('token');

if(token_storage!=null){

      if(!store.state.usuario){
          console.log('token encontrado');
          console.log(token_storage);
          store.dispatch("guardarToken", token_storage);
      }

}




  if(to.matched.some(record=>record.meta.libre)){
    next();


    //validamos rutas solo administrador
  }else if( store.state.usuario && store.state.usuario.rol=='ADMIN'){

    if(to.matched.some(record=>record.meta.administrador)){
      next();
    }

    //ruta para usuarios comunes
  }else if(store.state.usuario && store.state.usuario.rol=='USER'){

    if(to.matched.some(record=>record.meta.usuario_comun)){
          next();
        }

    //si no tiene ningun permiso salir
  }else{
    next({name:'login'});
    console.log('entro en ruta libre');

  }

});



export default router
